import React, { useRef } from "react";
import "./Header.css";
import { Badge, Box } from "@mui/material";
import { useSelector } from "react-redux";
import {
  MyAccountDropdown,
  ProductHeaderLogo,
  ProductHeader,
  Logos,
  ProductTitleText,
  MenuItem,
  Button
} from "@cambridgeassessment/cambridge-ui";
import labels from "./labels";
import  NotificationsOutlined from "@mui/icons-material/NotificationsOutlined";
import { useState } from "react";
import { useAuth } from "react-oidc-context";
import { constants } from "../constants";

const Header = ({enableSticky}) => {
  const [open, setOpen] = useState(false),
        [bell, setBell] = useState(false),
        userInfo = useSelector((state) => state.userInfo),
        smallDevice = useRef(false),
        auth = useAuth();
  const redirectToCambridge = () => {
    sessionStorage.clear();
    window.location.href = process.env.REACT_APP_REDIRECT_URL;
  };

  window.addEventListener('resize',(e)=>{
     if(e.target.innerWidth <1024 && !userInfo.smallScreen){
       smallDevice.current = true;
     }else{
       smallDevice.current = false;
     }
  })
  
  return (
    <>
    <ProductHeader className={`${enableSticky?"scroll_sticky":""}`}>
        <div className={`${(smallDevice.current)?"logo_w_resize":"logo_width"}`}>{constants.product_logo}</div>
        <ProductTitleText>{labels.TITLE}</ProductTitleText>
        {/* <Badge badgeContent={5} color="primary">
          <button data-toggle="modal" data-target="#exampleModal" className="btn-notifi" onClick={()=>{setBell(!bell)}}>
            <NotificationsOutlined  color="action" />
          </button>
        </Badge>         */}
        <Box ml="auto">        
          <MyAccountDropdown 
            onClick={() => setOpen(true)}
            onClose={() => setOpen(false)}
            user={{
              id: "1",
              imageSrc: "",
              name: `${userInfo?.name}`,
              role: `${userInfo?.roleFromSAP}`,
            }}
            open={open}
          >
            <MenuItem onClick={redirectToCambridge}>Go to My Cambridge</MenuItem>
          </MyAccountDropdown>
        </Box>
      </ProductHeader>  
    </>
  );
};
export default Header;
