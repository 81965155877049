import "../../common/global.css";
import "./Dashboard.css";
import { constants } from "../../constants";
import { useDispatch,useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setTabNumber } from "../../redux/slice/sideNavigation";
import SchoolInsight from "./schoolInsight/schoolInsight";
import { Button, Card, Navigation, NavigationItem, Notification, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@cambridgeassessment/cambridge-ui";
import { downloadStudentOrSyllabus, getDistrictOrStateOverviewData, getPassRateData } from "../../services/muleSoftServices";
import { setAlert } from "../../redux/slice/GlobalMessage";
import { isEmpty } from "lodash";
import { useEffect, useRef, useState } from "react";
import { setSeries } from "../../redux/slice/passRate";
import Spinner from "../../common/Spinner";
import { showPendingNotification } from "../../common/validations";
import { DownloadModal } from "../../common/Modal";

const Dashboard = () => {
  const dispatch = useDispatch(),
        navigate = useNavigate(),
        initialState ={
          spin:true,
          headerLabel:"",
          headerSubLabel:"",
          dSecLabel:"",
          csvLabel:"",
          zipLabel:"",
          enhanceRepoIconArr:[],
          navItem:0,
          overViewLabel:""
        },
        date = new Date(),
        year = date.getFullYear(),
        [initialData,setInitialData] = useState(initialState),
        [aLevelexams, setALevelExams] = useState([]),
        [asLevelexams, setASLevelExams] = useState([]),
        [iGCSEexams,setIGCSEexams] = useState([]),
        [openDownloadModal,setOpenDownloadModal] = useState(false),
        [months,setMonths] = useState([]),
        [districtOverViewData,setDistricOverViewtData] = useState([]),
        initialized = useRef(false),
        aLevelResp = useRef(false),
        asLevelResp = useRef(false),
        iGCSEResp = useRef(false),
        seriesSelected = useRef(""),
        notificationStatus = useRef("pending"),
        spinnerRef = useRef(),
        showResultsPendingNotification = useRef(true),
        retrievedOverview = useRef(false),
        userInfo = useSelector(state => state.userInfo),
        {totalLoggedInData,orgs,roleLevel,district,
         stateName,districtName,emlDistrictObj,
         emlCentreObj,emlCentre,emlDistrict,
         emlState,roleFromSAP,emlStateObj,
         state,uid,hierarchy} = userInfo,                   
        notificationWebSocket = useSelector(state=>state.notificationWebSocket),
        {websocketInstance} = notificationWebSocket,
        tabChange = (s,section) => {
          if(section === "school"){
            dispatch(setTabNumber({managementTabNumber:-1,reportTabNumber:s.tab}));
          }else{
            dispatch(setTabNumber({managementTabNumber:s.tab,reportTabNumber:-1}));
          }
          navigate(s.path);
        };
  
  window.history.pushState(null, null, window.location.href);
  window.onpopstate = function () {
      window.history.go(1);
  };

  useEffect(()=>{
    window.scrollTo(0,0);
    const {downloadLabel,downloadDistrictLabel,downloadStateLabel,
           teacherReportLabel,subHeader2} = constants.dashboard,          
          date = new Date(),
          month = date.getMonth()+1,
          year = date.getFullYear();
    if(roleLevel === "exams" || emlCentre){
      setInitialData((previousState)=>{
        return{...previousState,
              headerLabel: downloadLabel,
              headerSubLabel:roleLevel === "support"?emlCentreObj.centreName:orgs[0]?.name,
              dSecLabel: "Reports",
              csvLabel: "Exam grade data",
              zipLabel: "Syllabus component marks"}
      }) 
    }
    if(roleLevel === "head"){
      setInitialData((previousState)=>{
        return{...previousState,
              headerLabel: downloadLabel,
              headerSubLabel: orgs[0]?.name,
              csvLabel: "Exam grade data",
              zipLabel: "Syllabus component marks",
              dSecLabel: "Results"}
      })
    }
    if(roleLevel === "teacher"){
      setInitialData((previousState)=>{
        return{...previousState,
              headerLabel: teacherReportLabel,
              headerSubLabel: subHeader2}
      })
    }
    if(roleLevel === "district" || emlDistrict){
      setInitialData((previousState)=>{
        return{...previousState,
              headerLabel: downloadDistrictLabel,
              headerSubLabel: districtName+" , "+stateName,
              dSecLabel: "Results",
              csvLabel: "District results data file",
              zipLabel: "District syllabus data file",
              overViewLabel: "district overview",
              enhanceRepoIconArr: constants.sideNav.allManagementLinks.filter(e=>e.label.toLowerCase().includes('upload district data'))}
      })
    }
    if(roleLevel === "state" || emlState){
      setInitialData((previousState)=>{
        return{...previousState,
              headerLabel: downloadStateLabel,
              headerSubLabel: stateName,
              dSecLabel: "Reports",
              csvLabel: "State results data file",
              overViewLabel: "state overview",}})
    }
    if(month <7){
      setMonths([`June ${year}`,`November ${year-1}`,`June ${year-1}`]);
    }else if(month>6 && month <12){
      if((year === 2024 && month === 12) || year > 2024){
        setMonths([`November ${year}`,`June ${year}`,`November ${year-1}`]);
      }else{
        setMonths([`November ${year}`,`June ${year}`]);
      }
    }else{
      setMonths([`June ${year+1}`,`November ${year}`,`June ${year}`]);
    }    
  },[]);

  useEffect(()=>{    
    if(!initialized.current && months.length>0){
      initialized.current = true;
      let date = new Date(),
          year = date.getFullYear(),
          seriesList = [];
          months.forEach(s => {
          let grpLabel = "",
              yearCount = months.filter(f=>f.includes(s));
          if(s.includes(year)){
            grpLabel = year;
          }else if(s.includes(year-1)){
            grpLabel = year-1
          }else if(s.includes(year+1)){
            grpLabel = year+1
          }
          if(seriesList.length>=0){
            let yearExist = seriesList.filter(l=>l.label === grpLabel);
            if(!yearExist.length){
              seriesList.push({tag:"group",label:grpLabel})   
            }
          }
          yearCount.forEach(y=>{
            seriesList.push({tag:"item",label:y})
          })          
        });
      seriesSelected.current = months[0];
      dispatch(setSeries({seriesDropList:seriesList,seriesLabels:months}));
      if(roleLevel !== 'teacher'){
        getExamsData(seriesSelected.current,"A Level");
      }else{
        spinnerRef.current?.spinnerOpen();      
        setInitialData((previousState)=>{
          return {...previousState,spin:false}
        })
      }
    }
  },[months])

  useEffect(()=>{
    if(aLevelResp.current){
      getExamsData(seriesSelected.current,"AS Level");
    }
  },[aLevelResp.current])

  useEffect(()=>{
    if(asLevelResp.current){
      getExamsData(seriesSelected.current,"IGCSE");
    }
  },[asLevelResp.current])

  const getExamsData = async (series,qual) => {
    let sortedExams = [],modifiedParams={};        
    const {passRateReport} = constants.sideNav.roleBaseApiKeys;
    
    if(roleLevel === "support" && emlCentre){
      modifiedParams = {
        centreId:emlCentreObj.centreId
      }
    }else if(roleLevel === "support" && emlDistrict){
      modifiedParams = {
        districtId:emlDistrictObj.districtId,
        roleLevel:emlDistrictObj.emlLevel
      }
    }else if(roleLevel === "support" && emlState){
      modifiedParams = {
        stateId:stateName,
        roleLevel:emlStateObj.emlLevel
      }
    }

    const params = {token:totalLoggedInData.access_token,
                    roleName:roleFromSAP,
                    centreId:modifiedParams?.centreId?modifiedParams.centreId:orgs[0]?.sourceSystems[0]?.orgId,
                    series:[series],
                    quals:[qual],
                    bpId:orgs[0]?.bpid,
                    roleLevel:modifiedParams?.roleLevel?modifiedParams.roleLevel:passRateReport[roleLevel],
                    districtId:modifiedParams?.districtId?modifiedParams.districtId:district,
                    stateId:modifiedParams?.stateId?modifiedParams.stateId:stateName,
                    stateCode:state},
      examDetails = await getPassRateData(params);        
      if(examDetails?.error){        
        dispatch(setAlert({message:constants.alertMsgs[examDetails.message],status:examDetails.type}))
      }else{                         
        const examsCopy = examDetails?.data?.dataRows ? [...examDetails?.data?.dataRows]:[];
              sortedExams = examsCopy.sort((a, b) => { return a["passRate"] - b["passRate"]; });
      }
      if(qual === "A Level"){
        aLevelResp.current = true;
        setALevelExams(sortedExams);
      }else if(qual === "AS Level"){
        asLevelResp.current = true;
        setASLevelExams(sortedExams);
      }else{
        iGCSEResp.current = true;
        if(!sortedExams.length || showResultsPendingNotification.current){
          notificationStatus.current = showPendingNotification(series);
        }
        setIGCSEexams(sortedExams);
      }
      if(sortedExams.length>0 && showResultsPendingNotification.current){
        showResultsPendingNotification.current = false;
      }
      if((roleLevel === "district" || emlDistrict || roleLevel === "state" || emlState) && 
          iGCSEResp.current && !showResultsPendingNotification.current){
          if(!retrievedOverview.current){
            districtOrStateOverview();
          }        
      }else if(iGCSEResp.current){
        spinnerRef.current?.spinnerOpen();      
        setInitialData((previousState)=>{
          return {...previousState,spin:false}
        })
      }          
  };
  const downloadCsvOrZip = async(fileType) =>{
    let level,rName;
    setInitialData((previousState)=>{
      return {...previousState,spin:true}
    })
    const {csvDownloads} = constants.sideNav.roleBaseApiKeys;
    if(emlCentre){
      level = emlCentreObj.emlLevel
    }else if(emlDistrict){
      level = emlDistrictObj.emlLevel
    }else if(emlState){
      level = emlStateObj.emlLevel
    }
    const params = {
            token:totalLoggedInData.access_token,
            roleName:rName?rName:roleFromSAP,
            rLevel:level?csvDownloads[level]:csvDownloads[roleLevel],
            stateId:state?state:hierarchy[0].stateId,
            districtId:district?district:hierarchy[0].districtId,
            centreId:emlCentreObj?.centreId?emlCentreObj.centreId:orgs[0]?.sourceSystems[0]?.orgId,
            series:seriesSelected.current,
            uuid:uid,
            bpId:orgs[0]?.bpid,
            type:fileType === 'csv'?'school':"syllabus"              
          },
          res = await downloadStudentOrSyllabus(params);
    if(res?.error || isEmpty(res.data?.presignedUrl) || res.data?.presignedUrl.includes(null)){
      if(roleLevel === "exams" || emlCentre){
        if(!isEmpty(res.data?.message)){
          dispatch(setAlert({message:res.data.message,status:"info"}));        
        }else{
          dispatch(setAlert({message:"Facing some technical issue while downloading",status:"error"}));
        }
      }else{
        setOpenDownloadModal(true);
        await waitingForDownloadFromWS();
      }            
    }else{
      dispatch(setAlert({message:"File downloaded successfully",status:"success"}));
      window.open(res.data?.presignedUrl?res.data.presignedUrl:"","_self");
    }
    setInitialData((previousState)=>{
      return {...previousState,spin:false}
    })      
  }

  const waitingForDownloadFromWS = async()=>{
    websocketInstance.onmessage = function (event) {
    let response = JSON.parse(event.data);
    if(response.statusCode === 200){
      dispatch(setAlert({message:"Report generated, file downloading in progress...",status:"success"}));
      window.open(response?.properties?.preSignedUrl?response.properties.preSignedUrl:"","_self");
      setOpenDownloadModal(false);
      dispatch(setAlert({message:"File downloaded successfully",status:"success"}));
      }
    };    
  }

  const seriesNav = (e) =>{
    aLevelResp.current = false;
    asLevelResp.current = false;
    iGCSEResp.current = false;
    showResultsPendingNotification.current = true;

    if(e.currentTab >= 0){
      setInitialData((previousState)=>{
        return{...previousState,navItem:e.currentTab}
      })
    }else{           
      setInitialData((previousState)=>{
        return{...previousState,spin:true}
      })
      getExamsData(e?.target?.innerText,"A Level");      
      seriesSelected.current = e?.target?.innerText;
    }
  }

  const districtOrStateOverview = async() =>{
    let modifiedParams ={};
    const {districtOverview,stateOverview} = constants.sideNav.roleBaseApiKeys;
  if(roleLevel === "support" && emlDistrict){
    modifiedParams = {
      districtId:emlDistrictObj.districtId,
      roleLevel:emlDistrictObj.emlLevel
    }
  }else if(roleLevel === "support" && emlState){
      modifiedParams = {
        stateId:emlStateObj.stateId,
        roleLevel:emlStateObj.emlLevel    
    }
  }
    const params = {token:totalLoggedInData.access_token,
                    roleName:roleFromSAP,
                    series:[seriesSelected.current],
                    bpId:orgs[0]?.bpid,
                    roleLevel:roleLevel === "support"?modifiedParams.roleLevel:
                              roleLevel === "district"?districtOverview[roleLevel]:stateOverview[roleLevel],
                    districtId:roleLevel === "support"?modifiedParams.districtId:hierarchy[0].districtId,
                    stateId:modifiedParams?.stateId?modifiedParams.stateId:state},
      districtDetails = await getDistrictOrStateOverviewData(params);        
      if(districtDetails?.error){        
        dispatch(setAlert({message:constants.alertMsgs[districtDetails.message],status:districtDetails.type}))
      }else if(districtDetails?.data){                         
        setDistricOverViewtData(districtDetails.data);
      }     
      spinnerRef.current?.spinnerOpen();
      setInitialData((previousState)=>{
        return {...previousState,spin:false}
      })
  } 
  
  useEffect(()=>{
    spinnerRef.current?.spinnerOpen();
  },[initialData.spin])
  
  return (  
    <>
    {initialData.spin?<Spinner ref={spinnerRef} />:    
      <div className="row">
        <div className="txt col-lg-12 col-md-12 col-sm-12">
          <span className="lbl dash_lbl text-uppercase text-start">{initialData.headerLabel}</span>
        </div>
        <div className="col-12 row">         
          <div className="sub_text dash_sub_text">{initialData.headerSubLabel}</div>
          {roleLevel !== "teacher" && <Navigation style={{marginLeft:"15px",backgroundColor:"inherit"}} onChange={seriesNav} value={{currentTab:initialData.navItem}}>
            {months.map((m,i)=>{return(<NavigationItem className={`${window.innerWidth < 401 ? "w-25":window.innerWidth < 415 && m.includes("November")?"w-25":""}`} key={i} label={m} onClick={(e)=>seriesNav(e)}></NavigationItem>)})}            
          </Navigation>}
          {notificationStatus.current === "pending" && showResultsPendingNotification.current && roleLevel !== "teacher" && 
          <Notification variant="info" className="mt-4 ml-15">
            <p className="text-start"><strong>{seriesSelected.current} exams results pending</strong></p><p className="text-start">View exam <a className="link" target="_blank" href="https://www.cambridgeinternational.org/usa/students/dates-and-deadlines/">results release dates.</a></p>
            {roleLevel !== "head" && roleLevel !== "state" && 
            !emlState && <p className="text-start">In the meantime, you can enhance your reports by adding student data and teacher rosters.</p>}
            {roleLevel === "head" && <p className="text-start">In the meantime, you can enhance your grade reports by adding your student data and teacher rosters.</p>}
          </Notification>}
          {notificationStatus.current === "no data" && showResultsPendingNotification.current && roleLevel !== 'teacher' &&
              <Notification variant="info" className="mt-4 ml-15 text-start">Data not available for {seriesSelected.current}</Notification>
            }
          {!showResultsPendingNotification.current && <div className="mt-4">
            <Card>
              <div className="p-3">
                <p className="text-start fw-semibold m-0 mb-2">{seriesSelected.current} exam series data</p>
                <p className="text-start">Download raw data on exam grades and component marks.</p>
                {(roleLevel !== "state" && !emlState) && 
                  <div className="row text-start">
                    <div className="col-auto mb-2">
                      <Button className="h-auto" onClick={() => downloadCsvOrZip("csv")}>{constants.dashboard.downloadIcon} {initialData.csvLabel} (.csv)</Button>
                    </div>
                    <div className="col-auto mb-2">
                      <Button className="h-auto" onClick={() => downloadCsvOrZip("zip")}>{constants.dashboard.downloadIcon} {initialData.zipLabel} (.zip)</Button>
                    </div> 
                </div>
                }
                {(roleLevel === "state" || emlState) && <div className="text-start mb-3">
                  <Button className="h-auto" onClick={() => downloadCsvOrZip('csv')}>{constants.dashboard.downloadIcon} {initialData.csvLabel} (.csv)</Button>
                  </div>
                }
                {seriesSelected.current.toLowerCase().includes("june")?
                  <p className="text-start mt-2">{constants.dashboard.juneSeries}</p>:
                  <p className="text-start mt-2">{constants.dashboard.novemberSeries}</p>
                }              
              </div>
            </Card>
          </div>}
        </div>
        {showResultsPendingNotification.current && 
        ((roleLevel === "district" || emlDistrict || roleLevel === "head" ||
          roleLevel === "exams" || emlCentre)) &&
        <>
        <div className="txt mt-5">
          <span className="lbl text-uppercase text-start">enhance your reports</span>
        </div>
        <Notification variant="warning" className="mt-4 ml-15 enhance_m" style={{maxWidth:"-webkit-fill-available"}}>
          <p className="text-start mb-2"><strong>Window for adding enriched data is open!</strong></p>
          <p className="text-start mb-4">{constants.dashboard.seriesCalendarYear}</p>

          {(roleLevel === "exams" || emlCentre || roleLevel === "head") && <p className="text-start">
            <a id="dataLinks" onClick={()=> tabChange({tab:0,path:"/student-data"})}>Upload your student data</a> | <a id="dataLinks" onClick={()=>tabChange({tab:1,path:"/teacher-data"})}>Upload your teacher rosters</a>                
          </p>}
          {(roleLevel === "district" || emlDistrict) &&
            <p className="text-start"><a id="dataLinks" onClick={()=>tabChange({tab:0,path:"/district-data"})}>Upload your district data</a></p>
          }
        </Notification></>}
        {(roleLevel === "exams" || emlCentre || roleLevel === "head") && 
         !initialData.spin && !showResultsPendingNotification.current && 
         <div className="col-12 mt-3">
          <SchoolInsight aLevel={aLevelexams} asLevel={asLevelexams} iGcse={iGCSEexams} month={seriesSelected.current}></SchoolInsight>
        </div>}
        {(roleLevel === "exams" || emlCentre || roleLevel === "head")&&
         !showResultsPendingNotification.current &&
        <div className="mt-5">          
          <div className="mb-3 text-start online_lbl lbl text-uppercase">{constants.dashboard.header2}</div>
          <div className="row col-12">  
            <div className="col-lg-6 col-md-12 col-sm-12 ml_p_inherit">
              {constants.dashboard.dashboardShortCutLabels.col1.map((c, i) => {
                return (<a onClick={() => tabChange(c,"school")}>
                          <div key={i} className="card_size text-start m_12 w-100">{c.icon}{c.label}</div>
                        </a>);})}
            </div>
            <div className={`${window.innerWidth < 992 ?"m_shortLinks col-lg-6 col-md-12 col-sm-12 ml_p_inherit":"col-lg-6 col-md-12 col-sm-12 ml_p_inherit"}`}>
              {constants.dashboard.dashboardShortCutLabels.col2.map((c, i) => {
                return (<a onClick={() => tabChange(c,"school")}>
                          <div key={i} className="card_size text-start m_12 w-100">{c.icon}{c.label}</div>
                        </a>);})}
            </div>
          </div>
        </div>}
        
          <>
          {(roleLevel === "district" || emlDistrict || roleLevel === "state" || emlState) && !showResultsPendingNotification.current &&
          <>
            <div className="txt">
              <span className="lbl text-uppercase">{initialData.overViewLabel}</span>
            </div>
            {(roleLevel === "district" || emlDistrict) && <div className="sub_text mb-3">{seriesSelected.current}</div>}
            {(roleLevel === "state" || emlState) && <div className="sub_text mb-3">{stateName}</div>}
            {(roleLevel === "exams" || emlCentre) && <div className="sub_text mb-3">{emlCentreObj.centreId}</div>}
            
            <div className="overview">
            <TableContainer>
              <Table>
                <TableHead>
                    {(roleLevel === "district" || emlDistrict) && <TableCell></TableCell>}
                    {(roleLevel === "state" || emlState) && <TableCell><strong>{seriesSelected.current} exam series</strong></TableCell>}
                    <TableCell className="text-sm-center"><strong>AS Levels</strong></TableCell>
                    <TableCell className="text-sm-center"><strong>A Levels</strong></TableCell>
                    <TableCell className="text-sm-center"><strong>IGCSE</strong></TableCell>
                </TableHead>
                <TableBody>
                  {districtOverViewData.length>0 && districtOverViewData.map((d,i)=>(
                    <TableRow key={i}>
                      <TableCell>{constants.dashboard.overviewLabels[d.description]}</TableCell>
                      {d.ASLevel>=0 && d.ASLevel !==null && <TableCell className="text-sm-center">{i<= 1?d.ASLevel.toLocaleString():`${d.ASLevel}%`}</TableCell>}
                      {d.ALevel>=0 && d.ALevel !==null && <TableCell className="text-sm-center">{i<= 1?d.ALevel:`${d.ALevel}%`}</TableCell>}
                      {d.IGCSE>=0 && d.IGCSE !==null && <TableCell className="text-sm-center">{i<= 1?d.IGCSE:`${d.IGCSE}%`}</TableCell>}
                    </TableRow>
                  ))
                  }
                </TableBody>
              </Table>
            </TableContainer>
            </div>
          </>}

          {(roleLevel === "state" || emlState) && (aLevelexams.length>0 || asLevelexams.length>0 || iGCSEexams.length>0) &&
          <>
            <div className="txt mt-3">
              <span className="lbl text-uppercase online_state_lbl">state reports</span>
            </div>
            <div className="sub_text mb-3">Basic reports of your state</div>

            <div className="col-lg-6 col-md-12 col-sm-12 ml_p_inherit">
                <a onClick={() => tabChange({tab:1,path:"/pass-rates"},"school")}>
                  <div className="card_size text-start m_12 w-100">                  
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                      <path d="m344-60-76-128-144-32 14-148-98-112 98-112-14-148 144-32 76-128 136 58 136-58 76 128 144 32-14 148 98 112-98 112 14 148-144 32-76 128-136-58-136 58Zm34-102 102-44 104 44 56-96 110-26-10-112 74-84-74-86 10-112-110-24-58-96-102 44-104-44-56 96-110 24 10 112-74 86 74 84-10 114 110 24 58 96Zm102-318Zm-42 142 226-226-56-58-170 170-86-84-56 56 142 142Z" />
                    </svg>Pass rates
                  </div>
                </a>
                {/* <a onClick={() => tabChange({tab:2,path:"/aice-reports"},"school")}>
                  <div className="card_size text-start m_12 w-100">                  
                  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                    <path d="m392-420.46 33.08-108.62-87-67.07h108.23L480-704.31l33.69 108.16h108.23l-87.61 67.07 33.07 108.62L480-488l-88 67.54ZM260-70.77v-286.31q-38-39.69-59-91.77-21-52.07-21-111.15 0-125.54 87.23-212.77T480-860q125.54 0 212.77 87.23T780-560q0 59.08-21 111.15-21 52.08-59 91.77v286.31l-220-70-220 70ZM480-320q100 0 170-70t70-170q0-100-70-170t-170-70q-100 0-170 70t-70 170q0 100 70 170t170 70ZM320-158.62l160-42.92 160 42.92v-148.61q-34.23 22.31-74.92 34.77Q524.38-260 480-260q-44.38 0-85.08-12.46-40.69-12.46-74.92-34.77v148.61Zm160-74.3Z"/>
                    </svg>Cambridge AICE Diploma
                  </div>
                </a> */}
            </div>
            <div className={`col-lg-6 col-md-12 col-sm-12 ml_p_inherit ${window.innerWidth<992?"m_shortLinks":""}`}>
              <a onClick={() => tabChange({tab:2,path:"/demographics"},"school")}>
                <div className="card_size text-start m_12 w-100">
                  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                  <path d="M40-160v-160q0-34 23.5-57t56.5-23h131q20 0 38 10t29 27q29 39 71.5 61t90.5 22q49 0 91.5-22t70.5-61q13-17 30.5-27t36.5-10h131q34 0 57 23t23 57v160H640v-91q-35 25-75.5 38T480-200q-43 0-84-13.5T320-252v92H40Zm440-160q-38 0-72-17.5T351-386q-17-25-42.5-39.5T253-440q22-37 93-58.5T480-520q63 0 134 21.5t93 58.5q-29 0-55 14.5T609-386q-22 32-56 49t-73 17ZM160-440q-50 0-85-35t-35-85q0-51 35-85.5t85-34.5q51 0 85.5 34.5T280-560q0 50-34.5 85T160-440Zm640 0q-50 0-85-35t-35-85q0-51 35-85.5t85-34.5q51 0 85.5 34.5T920-560q0 50-34.5 85T800-440ZM480-560q-50 0-85-35t-35-85q0-51 35-85.5t85-34.5q51 0 85.5 34.5T600-680q0 50-34.5 85T480-560Z" />
                  </svg>Demographics
                </div>
              </a>
            </div>
          </>
          }
           <div className={roleLevel !== "teacher"?"mt-5":""}>
            {(aLevelexams.length>0 || asLevelexams.length>0 || iGCSEexams.length>0) && 
              (roleLevel === "district" || emlDistrict) && <div className="txt mt-3">
              <span className="lbl text-uppercase online_lbl text-start">enhance your reports</span>
            </div>}       
            {(roleLevel === "district" || emlDistrict) && <div className="row col-12">
              <div className="col-lg-6 col-md-12 col-sm-12 ml_p_inherit">
                  <a onClick={() => tabChange({tab:0,path:"/district-data"},"enhance")}>
                    <div className="card_size text-start m_12 w-100">{initialData.enhanceRepoIconArr[0]?.icon} Upload your district student and teacher data</div>
                  </a>
              </div>
              <div className="col-6 ml_p_inherit"></div>
            </div>}
           </div>
          </>
              
        
        {!showResultsPendingNotification.current && (roleLevel === "exams" || roleLevel === "head" || 
         (roleLevel === "support" && emlCentre)) && 
         <div className="txt mt-1">
          <div className="lbl text-start text-uppercase">enhance your reports</div>          
        </div>
        }
        {!showResultsPendingNotification.current && (roleLevel === "exams" || roleLevel === "head" || 
         (roleLevel === "support" && emlCentre)) &&
          <>
            <p className="text-start mt-2">The window is open to add enhanced data for {year} exams series.</p>
            <p className="text-start">{constants.dashboard.seriesCalendarYear}</p>
          </>
        }
        {(roleLevel === "exams" || emlCentre || roleLevel === "head") && 
         <div className="row col-12 mt-2">
            <div className="col-lg-6 col-md-12 col-sm-12 ml_p_inherit">
                <a onClick={() => tabChange({tab:0,path:"/student-data"},"enhance")}>
                  <div className="card_size text-start m_12 w-100">                  
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="16">
                      <path d="M720-240q25 0 42.5-17.5T780-300q0-25-17.5-42.5T720-360q-25 0-42.5 17.5T660-300q0 25 17.5 42.5T720-240Zm0 120q30 0 56-14t43-39q-23-14-48-20.5t-51-6.5q-26 0-51 6.5T621-173q17 25 43 39t56 14Zm-520 0q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v268q-19-9-39-15.5t-41-9.5v-243H200v560h242q3 22 9.5 42t15.5 38H200Zm0-120v40-560 243-3 280Zm80-40h163q3-21 9.5-41t14.5-39H280v80Zm0-160h244q32-30 71.5-50t84.5-27v-3H280v80Zm0-160h400v-80H280v80ZM720-40q-83 0-141.5-58.5T520-240q0-83 58.5-141.5T720-440q83 0 141.5 58.5T920-240q0 83-58.5 141.5T720-40Z"/>
                    </svg>Add student data
                  </div>
                </a>
            </div>
            <div className={`${window.innerWidth < 992 ?"m_shortLinks col-lg-6 col-md-12 col-sm-12 ml_p_inherit":"col-lg-6 col-md-12 col-sm-12 ml_p_inherit"}`}>
              <a onClick={() => tabChange({tab:1,path:"/teacher-data"},"enhance")}>
                <div className="card_size text-start m_12 w-100">
                  <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="16">
                    <path d="M680-320q-50 0-85-35t-35-85q0-50 35-85t85-35q50 0 85 35t35 85q0 50-35 85t-85 35Zm0-80q17 0 28.5-11.5T720-440q0-17-11.5-28.5T680-480q-17 0-28.5 11.5T640-440q0 17 11.5 28.5T680-400ZM440-40v-116q0-21 10-39.5t28-29.5q32-19 67.5-31.5T618-275l62 75 62-75q37 6 72 18.5t67 31.5q18 11 28.5 29.5T920-156v116H440Zm79-80h123l-54-66q-18 5-35 13t-34 17v36Zm199 0h122v-36q-16-10-33-17.5T772-186l-54 66Zm-76 0Zm76 0Zm-518 0q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v200q-16-20-35-38t-45-24v-138H200v560h166q-3 11-4.5 22t-1.5 22v36H200Zm80-480h280q26-20 57-30t63-10v-40H280v80Zm0 160h200q0-21 4.5-41t12.5-39H280v80Zm0 160h138q11-9 23.5-16t25.5-13v-51H280v80Zm-80 80v-560 137-17 440Zm480-240Z"/>
                  </svg>Add teacher rosters
                </div>
              </a>
            </div>
        </div>}
        {roleLevel === "teacher" && <><div className="row col-12 mt-2">
            <div className="col-lg-6 col-md-12 col-sm-12 ml_p_inherit">
                <a onClick={() => tabChange({tab:1,path:"/grade-distribution"},"school")}>
                  <div className="card_size text-start m_12 w-100">                  
                    <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                      <path d="m344-60-76-128-144-32 14-148-98-112 98-112-14-148 144-32 76-128 136 58 136-58 76 128 144 32-14 148 98 112-98 112 14 148-144 32-76 128-136-58-136 58Zm34-102 102-44 104 44 56-96 110-26-10-112 74-84-74-86 10-112-110-24-58-96-102 44-104-44-56 96-110 24 10 112-74 86 74 84-10 114 110 24 58 96Zm102-318Zm-42 142 226-226-56-58-170 170-86-84-56 56 142 142Z" />
                    </svg>Grade distribution
                  </div>
                </a>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 ml_p_inherit">
              <a onClick={() => tabChange({tab:3,path:"/student-reports"},"school")}>
                <div className="card_size text-start m_12 w-100">
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                  <path d="M320-480v-80h320v80H320Zm0-160v-80h320v80H320Zm-80 240h300q29 0 54 12.5t42 35.5l84 110v-558H240v400Zm0 240h442L573-303q-6-8-14.5-12.5T540-320H240v160Zm480 80H240q-33 0-56.5-23.5T160-160v-640q0-33 23.5-56.5T240-880h480q33 0 56.5 23.5T800-800v640q0 33-23.5 56.5T720-80Zm-480-80v-640 640Zm0-160v-80 80Z" />
                </svg>Student grade reports
                </div>
              </a>
            </div>
        </div>
        <div className="row col-12 mt-2">
        <div className={`col-lg-6 col-md-12 col-sm-12 ml_p_inherit ${window.innerWidth < 992 ?"m_shortLinks":""}`}>
            <a onClick={() => tabChange({tab:2,path:"/syllabus-reports"},"school")}>
              <div className="card_size text-start m_12 w-100">                  
                <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
                  <path d="M240-280h240v-80H240v80Zm120-160h240v-80H360v80Zm120-160h240v-80H480v80ZM200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-560H200v560Zm0-560v560-560Z" />
                </svg>Components
              </div>
            </a>
        </div>
        <div className="col-6 ml_p_inherit"></div>
        </div>
        </>}
        <DownloadModal open={openDownloadModal} close={()=> setOpenDownloadModal(!openDownloadModal)} />
      </div>
    }
    </>
  );
};
export default Dashboard;